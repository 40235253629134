import logo from './assets/Coin/Coin.png'
import './App.css'
import Header from './Header'
import { useRef, useState } from 'react'
import Wishlist from './Wishlist'
import Business from './Business'
import Platforms from './Platforms'
import {
  Button,
  CssBaseline,
  Grid,
  Icon,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import theme from './theme'
import HeaderBar from './components/HeaderBar'
import {
  ArrowForward,
  ArrowForwardIos,
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  MoveDown,
  MoveDownOutlined,
} from '@mui/icons-material'
import CTASignUpButton from './components/CTASignUpButton'
import SignUpModal from './SignUpModal'
import LandingSignUpModal from './components/LandingSignUpModal'
import MobileMenuModal from './components/MobileMenuModal'

function App() {
  const wishlistRef = useRef(null)
  const businessRef = useRef(null)
  const platformsRef = useRef(null)
  const large = useMediaQuery(theme.breakpoints.up('sm'))
  const [modalOpen, setModalOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App" style={{ margin: 0 }}>
        {/* <Header /> */}

        <header
          className="App-header"
          style={{
            backgroundImage: large
              ? `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.6)), url(${logo})`
              : `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.6))`,
            backgroundSize: 'contain',
            backgroundPosition: 'right -65% top 25%',
            backgroundBlendMode: 'saturate',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <HeaderBar
            onClickWishlist={() =>
              wishlistRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }
            onClickBusiness={() =>
              businessRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }
            onClickPlatforms={() =>
              platformsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }
            onClickMenu={() => setMobileMenuOpen(true)}
          />

          <Grid
            container
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              textAlign: 'left',
              padding: large ? '95px' : '25px',
              paddingBottom: '8px',
              paddingTop: '115px',
            }}
          >
            <div
              style={{
                color: '#77F8DE',
                fontSize: `calc(10px + 6vmin)`,

                fontFamily: 'Poppins',
                fontWeight: '900',
                wordWrap: 'break-word',
              }}
            >
              Welcome to <br />
              Wishing Well
            </div>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              textAlign: 'left',
              padding: large ? '105px' : '30px',

              paddingTop: '8px',
            }}
          >
            <div
              style={{
                width: '80%',
                color: '#F3FFFA',
                fontSize: `calc(6px + 2vmin)`,
                fontFamily: 'Poppins',
                fontWeight: '800',
                wordWrap: 'break-word',
              }}
            >
              We want to democratize the advertising and personal data industry.
              Our hope is that we can provide a win-win marketplace in the
              advertising world. Advertisers get hot-leads to specific customers
              ready to buy specific products at the same price as the channels
              they're used to. Customers get specific ads while experiencing the
              profit upside that comes with the digital advertising landscape.
              Still curious how this all works?{' '}
            </div>
          </Grid>

          <Grid
            container
            sx={{
              justifyContent: large ? 'flex-end' : 'center',
              alignItems: 'flex-start',
              textAlign: 'left',
              paddingRight: large ? '105px' : '0px',
            }}
          >
            <CTASignUpButton
              text="SIGN UP FOR FREE"
              onClick={() => setModalOpen(true)}
            />
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: large ? 'flex-end' : 'center',
              alignItems: large ? 'flex-start' : 'center',
              textAlign: large ? 'left' : null,
              paddingRight: large ? '115px' : '0px',
            }}
          >
            <div
              style={{
                width: '486px',
                paddingTop: '16px',
              }}
            >
              <Button
                onClick={() =>
                  wishlistRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  })
                }
                style={{
                  borderRadius: 10,
                  overflow: 'hidden',
                  justifyContent: 'flex-start',
                  alignItems: 'center',

                  cursor: 'pointer',
                }}
              >
                <ArrowForwardIos
                  sx={{
                    color: '#77F8DE',
                    fontSize: 32,
                    rotate: '90deg',
                    marginRight: '16px',
                  }}
                />
                <div
                  style={{
                    textAlign: 'center',
                    color: '#77F8DE',
                    fontSize: 26,
                    fontFamily: 'Poppins',
                    fontWeight: '800',
                    letterSpacing: 7.8,
                    wordWrap: 'break-word',
                  }}
                >
                  LEARN MORE
                </div>
              </Button>
            </div>
          </Grid>
        </header>
        <div className="Wishlist-header" ref={wishlistRef}>
          <Wishlist />
        </div>
        <div className="Business-header" ref={businessRef}>
          <Business />
        </div>
        <div className="Platforms-header" ref={platformsRef}>
          <Platforms />
        </div>
      </div>
      <LandingSignUpModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <MobileMenuModal
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        onClickWishlist={() => {
          wishlistRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
          setMobileMenuOpen(false)
        }}
        onClickBusiness={() => {
          businessRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
          setMobileMenuOpen(false)
        }}
        onClickPlatforms={() => {
          platformsRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
          setMobileMenuOpen(false)
        }}
      />
    </ThemeProvider>
  )
}

export default App

import img1 from './assets/wish1.jpg'
import img2 from './assets/wish2.jpg'
import img3 from './assets/wish3.jpg'
import './App.css'
import { useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import axios from 'axios'

function SignUpModal() {
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [extra, setExtra] = useState('')
  const [signUpForm, setSignUpForm] = useState(false)

  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('sm'))

  const handleOpenSignUpForm = () => {
    setSignUpForm(true)
  }

  const handleSubmitForm = async () => {
    console.log('SUBMITTING')
    const body = {
      contactName,
      contactEmail,
      extra,
    }
    const postDataUrl =
      'https://ewqywmm35ci2zbv2qzbpariecq0gplft.lambda-url.us-east-1.on.aws/'
    try {
      const putResponse = await axios.post(postDataUrl, body)
      console.log(putResponse)
    } catch (error) {
      console.log(error)
    }

    setContactName('')
    setContactEmail('')
    setExtra('')
    setSignUpForm(false)
  }

  return (
    <>
      <div
        style={{
          backgroundColor: '#282c34',
          borderRadius: '16px',
          margin: '32px',
          padding: '16px',
          color: 'white',
          cursor: 'pointer',
        }}
        onClick={handleOpenSignUpForm}
      >
        Visit Platform Docs
      </div>
      <Dialog open={signUpForm}>
        <DialogTitle> Sorry! Our documentation is coming soon!</DialogTitle>
        <DialogContent>
          <Typography sx={{ pb: 2 }}>
            Thanks for your interest in WishingWell! We're still working on our
            documentation, but we'd love to get in touch with you when it's
            ready.
          </Typography>
          <FormControl fullWidth sx={{ gap: 2 }}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              label="What's something you'd love to see from our platform support?"
              variant="outlined"
              value={extra}
              onChange={(e) => setExtra(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmitForm}
            variant="contained"
            sx={{ borderRadius: 1, display: 'flex', gap: 1 }}
          >
            <Typography sx={{ fontWeight: 'bold', color: 'White' }}>
              Submit
            </Typography>
            <ArrowForwardIosIcon sx={{ color: 'white' }} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SignUpModal

import logo from './assets/Coin/Coin.png'
import './App.css'
import { useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'

import { IconButton, useMediaQuery, useTheme } from '@mui/material'
import SignUpModal from './SignUpModal'

function Platforms() {
  const [currentPage, setCurrentPage] = useState(0)
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('sm'))

  const renderPageOne = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '64px',
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <h1 style={{ padding: 16, margin: 0 }}>
            Grow a sustainable business model
          </h1>
          <p style={{ padding: 16, margin: 0, color: '#282c34' }}>
            Support your platform with a sustainable business model. Earn
            revenue from ads that your users actually want to see.
          </p>
        </div>
        {/* <div>
          <img src={img1} className="App-logo" alt="wishlist-1" />
        </div> */}
      </div>
    )
  }

  const renderPageTwo = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '64px',
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <h1 style={{ padding: 16, margin: 0 }}>
            Offload your advertiser network
          </h1>
          <p style={{ padding: 16, margin: 0, color: '#282c34' }}>
            Spend your time building a platform that your users love. Let us
            handle the advertisers.
          </p>
        </div>
        {/* <div>
          <img src={img1} className="App-logo" alt="wishlist-1" />
        </div> */}
      </div>
    )
  }

  const renderPageThree = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '64px',
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <h1 style={{ padding: 16, margin: 0 }}>
            Give your community ads that they'll love
          </h1>
          <p style={{ padding: 16, margin: 0, color: '#282c34' }}>
            Allow your users to curate their own ads, curate ads that fit your
            community values, or a mix of both! Let them choose what they want
            to see, flag ads that they don't like, and more.
          </p>
        </div>
        {/* <div>
          <img src={img1} className="App-logo" alt="wishlist-1" />
        </div> */}
      </div>
    )
  }

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {false && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            width: '100%',
          }}
        >
          {large && <p style={{ margin: '32px' }}>Platforms</p>}
          <SignUpModal />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {large && (
          <div
            style={{
              marginLeft: '32px',
              height: '90vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {' '}
            {currentPage > 0 ? (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#003063',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <ArrowBackIosIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#003063',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(2)}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}
          </div>
        )}
        <div style={{ margin: '32px', marginTop: large ? '20vh' : '20vh' }}>
          {currentPage === 0 ? renderPageOne() : null}
          {currentPage === 1 ? renderPageTwo() : null}
          {currentPage === 2 ? renderPageThree() : null}
          {!false && <SignUpModal />}
        </div>
        {large && (
          <div
            style={{
              marginRight: '32px',
              height: '90vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {' '}
            {currentPage < 2 ? (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#003063',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#003063',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(0)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {currentPage === 0 ? (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(0)}>
            ◉
          </div>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(0)}>
            ◎
          </div>
        )}
        {currentPage === 1 ? (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(1)}>
            ◉
          </div>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(1)}>
            ◎
          </div>
        )}{' '}
        {currentPage === 2 ? (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(2)}>
            ◉
          </div>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(2)}>
            ◎
          </div>
        )}
      </div>
    </div>
  )
}

export default Platforms

import { useMediaQuery, useTheme } from '@mui/material'
import theme from '../theme'
import HeaderBarButton from './HeaderBarButton'
import { Menu } from '@mui/icons-material'
import { useState } from 'react'

function HeaderBar(props) {
  const { onClickWishlist, onClickBusiness, onClickPlatforms, onClickMenu } =
    props
  const large = useMediaQuery(theme.breakpoints.up('md'))

  if (large) {
    return (
      <div
        style={{
          paddingLeft: 25,
          paddingRight: 25,
          paddingTop: 15,
          paddingBottom: 15,
          background: '#77F8DE',
          boxShadow: '0px 25px 30px #00201B',
          borderRadius: 25,
          overflow: 'hidden',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 45,
          display: 'flex',
          position: 'fixed',
        }}
      >
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,

            background: '#005045',
            borderRadius: 16.25,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'flex',
          }}
        >
          <div
            style={{
              color: '#77F8DE',
              fontSize: 38,
              fontFamily: 'Poppins',
              fontWeight: '900',
              wordWrap: 'break-word',
              cursor: 'pointer',
            }}
          >
            WISHING WELL
          </div>
        </div>

        <HeaderBarButton text="WISHLIST" onClick={onClickWishlist} />
        <HeaderBarButton text="BUSINESS" onClick={onClickBusiness} />
        <HeaderBarButton text="PLATFORMS" onClick={onClickPlatforms} />
      </div>
    )
  } else {
    return (
      <>
        <div
          style={{
            paddingLeft: 25,
            paddingRight: 25,
            paddingTop: 15,
            paddingBottom: 15,
            background: '#77F8DE',
            boxShadow: '0px 25px 30px #00201B',
            borderRadius: 25,
            overflow: 'hidden',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 45,
            display: 'flex',
            position: 'fixed',
          }}
        >
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              background: '#005045',
              borderRadius: 16.25,
              overflow: 'hidden',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 10,
              display: 'flex',
            }}
          >
            <div
              style={{
                color: '#77F8DE',
                fontSize: `calc(10px + 4vmin)`,
                fontFamily: 'Poppins',
                fontWeight: '900',
                wordWrap: 'break-word',
                cursor: 'pointer',
              }}
            >
              WISHING WELL
            </div>
            <Menu
              sx={{
                color: '#77F8DE',
                fontSize: 38,
                fontFamily: 'Poppins',
                fontWeight: '900',
                wordWrap: 'break-word',
                cursor: 'pointer',
              }}
              onClick={onClickMenu}
            />
          </div>

          {/* <HeaderBarButton text="WISHLIST" onClick={onClickWishlist} />
        <HeaderBarButton text="BUSINESS" onClick={onClickBusiness} />
        <HeaderBarButton text="PLATFORMS" onClick={onClickPlatforms} /> */}
        </div>
      </>
    )
  }
}

export default HeaderBar

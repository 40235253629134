import { forwardRef, useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import axios from 'axios'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import HeaderBarButton from './HeaderBarButton'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function MobileMenuModal(props) {
  return (
    <Dialog
      open={props.open}
      fullScreen
      onClose={props.onClose}
      TransitionComponent={Transition}
      sx={{ m: 6 }}
      PaperProps={{
        sx: {
          backgroundColor: '#00201B',
          boxShadow: 'none',
          borderRadius: 2,
        },
      }}
    >
      <DialogContent>
        <DialogTitle sx={{ mb: 4 }}>
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon sx={{ fontSize: 38, color: '#77F8DE' }} />
          </IconButton>
        </DialogTitle>
        <Grid container gap={3}>
          <Grid container sx={{ justifyContent: 'flex-start' }}>
            <HeaderBarButton
              text="WISHLIST"
              onClick={props.onClickWishlist}
              mobile
              background="#E6DEFF"
            />
          </Grid>
          <Grid container sx={{ justifyContent: 'flex-start' }}>
            <HeaderBarButton
              text="BUSINESS"
              onClick={props.onClickBusiness}
              mobile
              background="#95F8A2"
            />
          </Grid>
          <Grid container sx={{ justifyContent: 'flex-start' }}>
            <HeaderBarButton
              text="PLATFORMS"
              onClick={props.onClickPlatforms}
              mobile
              background="#A9C7FF"
            />
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Button
          onClick={handleSubmitForm}
          variant="contained"
          sx={{ borderRadius: 1, display: 'flex', gap: 1 }}
        >
          <Typography sx={{ fontWeight: 'bold', color: 'White' }}>
            Submit
          </Typography>
          <ArrowForwardIosIcon sx={{ color: 'white' }} />
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

export default MobileMenuModal

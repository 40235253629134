import { useMediaQuery, useTheme } from '@mui/material'
import theme from '../theme'
import { useState } from 'react'

function HeaderBarButton(props) {
  const [hovered, setHovered] = useState(false)
  const theme = useTheme()

  if (hovered) {
    return (
      <div
        onMouseLeave={() => setHovered(false)}
        style={{
          background: 'linear-gradient(0deg, #77F8DE 0%, #77F8DE 100%)',
          borderRadius: 16.75,
          boxShadow: '0px 4px 4px rgba(0, 80, 69, 0.45)',
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          display: 'flex',
        }}
        onClick={props.onClick}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            paddingLeft: 8,
            paddingRight: 8,

            cursor: 'pointer',
            borderRadius: 16.75,
            overflow: 'hidden',
            //boxSizing: 'border-box',

            background:
              'linear-gradient(0deg, rgba(243, 255, 250, 0.35) 0%, rgba(243, 255, 250, 0.35) 100%)',
            border: ' 1px #58DBC3 solid',
            //boxSizing: 'content-box',
            boxShadow: '0px 4px 4px rgba(0, 80, 69, 0.45)',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'flex',
          }}
        >
          <div
            style={{
              color: '#005045',
              fontSize: props.mobile
                ? `calc(10px + 4vmin)`
                : `calc(10px + 2vmin)`,
              fontFamily: 'Poppins',
              fontWeight: '900',
              wordWrap: 'break-word',
            }}
          >
            {props.text}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        onClick={props.onClick}
        onMouseLeave={() => setHovered(false)}
        style={{
          background: '#77F8DE',
          borderRadius: 16.75,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          display: 'flex',
          width: props.mobile ? '100%' : 'auto',
          background:
            props.mobile && props.background ? props.background : '#77F8DE',
        }}
      >
        <div
          onMouseEnter={() => setHovered(true)}
          style={{
            paddingLeft: 9,
            paddingRight: 9,

            background:
              props.mobile && props.background ? props.background : '#77F8DE',
            borderRadius: 16.75,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'flex',
          }}
        >
          <div
            style={{
              color: '#005045',
              fontSize: props.mobile
                ? `calc(10px + 4vmin)`
                : `calc(10px + 2vmin)`,
              fontFamily: 'Poppins',
              fontWeight: '900',
              wordWrap: 'break-word',
            }}
          >
            {props.text}
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderBarButton

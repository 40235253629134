import logo from './assets/Coin/Coin.png'
import './App.css'

function Header() {
  return (
    <div
      style={{
        width: '100%',
        height: '60px',
        position: 'relative',
        background: '#00382F',
      }}
    >
      <div
        style={{
          left: 95,
          top: 253,
          position: 'absolute',
          color: '#77F8DE',
          fontSize: 95,
          fontFamily: 'Poppins',
          fontWeight: '900',
          wordWrap: 'break-word',
        }}
      >
        Welcome to <br />
        Wishing Well
      </div>
      <div
        style={{
          width: 1191,
          left: 105,
          top: 566,
          position: 'absolute',
          color: '#F3FFFA',
          fontSize: 30,
          fontFamily: 'Poppins',
          fontWeight: '800',
          wordWrap: 'break-word',
        }}
      >
        We want to democratize the advertising and personal data industry. Our
        hope is that we can provide a win-win marketplace in the advertising
        world. Advertisers get hot-leads to specific customers ready to buy
        specific products at the same price as the channels they're used to.
        Customers get specific ads while experiencing the profit upside that
        comes with the digital advertising landscape. Still curious how this all
        works?{' '}
      </div>
      <div
        style={{
          paddingLeft: 45,
          paddingRight: 45,
          paddingTop: 35,
          paddingBottom: 35,
          left: 1085,
          top: 896,
          position: 'absolute',
          background: '#77F8DE',
          boxShadow: '0px 20px 55px #00201B',
          borderRadius: 10,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          display: 'inline-flex',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            color: '#00201B',
            fontSize: 30,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 9,
            wordWrap: 'break-word',
          }}
        >
          SIGN UP FOR FREE
        </div>
      </div>
      <div
        style={{
          width: 1728,
          height: 2234,
          left: 0,
          top: 1117,
          position: 'absolute',
          background: '#005045',
        }}
      />
      <div
        style={{
          width: 529,
          height: 857,
          left: 599,
          top: 1332,
          position: 'absolute',
        }}
      >
        <div
          style={{
            width: 529,
            height: 857,
            left: 0,
            top: 0,
            position: 'absolute',
            background: '#7ADB88',
            borderRadius: 40,
          }}
        />
        <div
          style={{
            left: 40,
            top: 7,
            position: 'absolute',
            textAlign: 'center',
            color: '#002108',
            fontSize: 38,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 9.5,
            wordWrap: 'break-word',
          }}
        >
          BUSINESS
        </div>
        <div
          style={{
            width: 449,
            height: 777,
            left: 40,
            top: 40,
            position: 'absolute',
            background: '#003913',
            borderRadius: 20,
          }}
        />
        <div
          style={{
            width: 369,
            left: 80,
            top: 245,
            position: 'absolute',
            color: '#F6FFF1',
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 1,
            wordWrap: 'break-word',
          }}
        >
          Fill in all the standard ad aspects you know and love Throw in your
          picture, video, or GIF media content Add surveys to help understand
          your customers. Organize, manage, and version control your ads!
        </div>
        <div
          style={{
            width: 357,
            height: 124.67,
            left: 86,
            top: 80,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 0,
              position: 'absolute',
              color: '#F6FFF1',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            Build out
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 30,
              position: 'absolute',
              color: '#F6FFF1',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            digital
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 75,
              position: 'absolute',
              color: '#F6FFF1',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            ads
          </div>
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 417,
            top: 637,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#F6FFF1',
            }}
          ></div>
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 112,
            top: 673,
            position: 'absolute',
            transform: 'rotate(-180deg)',
            transformOrigin: '0 0',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#F6FFF1',
            }}
          ></div>
        </div>
        <div
          style={{
            width: 369,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 9,
            paddingBottom: 9,
            left: 80,
            top: 738,
            position: 'absolute',
            background: '#F6FFF1',
            boxShadow: '0px 15px 35px #002108',
            borderRadius: 10,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: '#002108',
              fontSize: 30,
              fontFamily: 'Poppins',
              fontWeight: '800',
              letterSpacing: 9,
              wordWrap: 'break-word',
            }}
          >
            LEARN MORE
          </div>
        </div>
        <div
          style={{
            left: 233,
            top: 656,
            position: 'absolute',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 6,
            display: 'inline-flex',
          }}
        >
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background: '#F6FFF1',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(246, 255, 241, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(246, 255, 241, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          width: 529,
          height: 857,
          left: 1153,
          top: 1332,
          position: 'absolute',
        }}
      >
        <div
          style={{
            width: 529,
            height: 857,
            left: 0,
            top: 0,
            position: 'absolute',
            background: '#A9C7FF',
            borderRadius: 40,
          }}
        />
        <div
          style={{
            left: 40,
            top: 7,
            position: 'absolute',
            textAlign: 'center',
            color: '#001B3D',
            fontSize: 38,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 9.5,
            wordWrap: 'break-word',
          }}
        >
          PLATFORM
        </div>
        <div
          style={{
            width: 449,
            height: 777,
            left: 40,
            top: 40,
            position: 'absolute',
            background: '#003063',
            borderRadius: 20,
          }}
        />
        <div
          style={{
            width: 357,
            height: 124.67,
            left: 86,
            top: 80,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 0,
              position: 'absolute',
              color: '#D6E3FF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            Grow a
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 30,
              position: 'absolute',
              color: '#D6E3FF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            sustainable
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 75,
              position: 'absolute',
              color: '#D6E3FF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            business model
          </div>
        </div>
        <div
          style={{
            width: 357,
            left: 86,
            top: 245,
            position: 'absolute',
            color: '#D6E3FF',
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 1,
            wordWrap: 'break-word',
          }}
        >
          Support your platform with a sustainable business model. Earn revenue
          from ads that your users actually want to see.
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 417,
            top: 637,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#A9C7FF',
            }}
          ></div>
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 112,
            top: 673,
            position: 'absolute',
            transform: 'rotate(-180deg)',
            transformOrigin: '0 0',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#A9C7FF',
            }}
          ></div>
        </div>
        <div
          style={{
            width: 369,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 9,
            paddingBottom: 9,
            left: 80,
            top: 738,
            position: 'absolute',
            background: '#D6E3FF',
            boxShadow: '0px 15px 35px #001B3D',
            borderRadius: 10,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: '#001B3D',
              fontSize: 30,
              fontFamily: 'Poppins',
              fontWeight: '800',
              letterSpacing: 9,
              wordWrap: 'break-word',
            }}
          >
            LEARN MORE
          </div>
        </div>
        <div
          style={{
            left: 233,
            top: 656,
            position: 'absolute',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 6,
            display: 'inline-flex',
          }}
        >
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background: '#D6E3FF',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(253, 251, 255, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(253, 251, 255, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 9,
          paddingBottom: 9,
          left: 1085,
          top: 1017,
          position: 'absolute',
          background: 'rgba(0, 32, 27, 0)',
          boxShadow: '0px 15px 35px #00201B',
          borderRadius: 10,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 8,
          display: 'inline-flex',
        }}
      >
        <div
          style={{
            width: 26,
            height: 26,
            position: 'relative',
            transform: 'rotate(90deg)',
            transformOrigin: '0 0',
          }}
        >
          <div
            style={{
              width: 12.76,
              height: 21.67,
              left: 6.77,
              top: 2.17,
              position: 'absolute',
              background: '#77F8DE',
            }}
          ></div>
        </div>
        <div
          style={{
            textAlign: 'center',
            color: '#77F8DE',
            fontSize: 26,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 7.8,
            wordWrap: 'break-word',
          }}
        >
          LEARN MORE
        </div>
      </div>
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 9,
          paddingBottom: 9,
          left: 1426,
          top: 1223.5,
          position: 'absolute',
          background: '#77F8DE',
          boxShadow: '0px 15px 35px #00201B',
          borderRadius: 10,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          display: 'inline-flex',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            color: '#00201B',
            fontSize: 26,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 7.8,
            wordWrap: 'break-word',
          }}
        >
          SIGN UP
        </div>
      </div>
      <div
        style={{
          width: 529,
          height: 857,
          left: 45,
          top: 1332,
          position: 'absolute',
        }}
      >
        <div
          style={{
            width: 529,
            height: 857,
            left: 0,
            top: 0,
            position: 'absolute',
            background: '#CBBEFF',
            borderRadius: 40,
          }}
        />
        <div
          style={{
            width: 449,
            height: 777,
            left: 40,
            top: 40,
            position: 'absolute',
            background: '#332074',
            borderRadius: 20,
          }}
        />
        <div
          style={{
            width: 369,
            left: 80,
            top: 245,
            position: 'absolute',
            color: '#E6DEFF',
            fontSize: 22,
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 1.1,
            wordWrap: 'break-word',
          }}
        >
          Curate a Wishlist of products and services you ACTUALLY want to see.
          Don't let algorithms tell you what to buy. Add keywords, brands,
          images, and more to your wishlist to find products you want to find.
        </div>
        <div
          style={{
            width: 369,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 9,
            paddingBottom: 9,
            left: 80,
            top: 738,
            position: 'absolute',
            background: '#E6DEFF',
            boxShadow: '0px 15px 35px #1D0160',
            borderRadius: 10,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: '#1D0160',
              fontSize: 30,
              fontFamily: 'Poppins',
              fontWeight: '800',
              letterSpacing: 9,
              wordWrap: 'break-word',
            }}
          >
            LEARN MORE
          </div>
        </div>
        <div
          style={{
            width: 357,
            height: 124.67,
            left: 80,
            top: 80,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 0,
              position: 'absolute',
              color: '#E6DEFF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            Make a
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 30,
              position: 'absolute',
              color: '#E6DEFF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            wish that
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 75,
              position: 'absolute',
              color: '#E6DEFF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            will come true
          </div>
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 417,
            top: 637,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#CBBEFF',
            }}
          ></div>
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 112,
            top: 673,
            position: 'absolute',
            transform: 'rotate(-180deg)',
            transformOrigin: '0 0',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#CBBEFF',
            }}
          ></div>
        </div>
        <div
          style={{
            left: 233,
            top: 656,
            position: 'absolute',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 6,
            display: 'inline-flex',
          }}
        >
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background: '#E6DEFF',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(255, 251, 255, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(255, 251, 255, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
        </div>
        <div
          style={{
            left: 40,
            top: 7,
            position: 'absolute',
            textAlign: 'center',
            color: '#1D0160',
            fontSize: 38,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 9.5,
            wordWrap: 'break-word',
          }}
        >
          WISHLIST
        </div>
      </div>
      <div
        style={{
          width: 529,
          height: 857,
          left: 45,
          top: 2449,
          position: 'absolute',
        }}
      >
        <div
          style={{
            width: 529,
            height: 857,
            left: 0,
            top: 0,
            position: 'absolute',
            background: '#CBBEFF',
            borderRadius: 40,
          }}
        />
        <div
          style={{
            width: 489,
            height: 817,
            left: 20,
            top: 20,
            position: 'absolute',
            background: '#332074',
            borderRadius: 30,
          }}
        />
        <div
          style={{
            width: 369,
            left: 80,
            top: 235,
            position: 'absolute',
            color: '#E6DEFF',
            fontSize: 22,
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 1.1,
            wordWrap: 'break-word',
          }}
        >
          Curate a Wishlist of products and services you ACTUALLY want to see.
          Don't let algorithms tell you what to buy. Add keywords, brands,
          images, and more to your wishlist to find products you want to find.
        </div>
        <div
          style={{
            width: 369,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 9,
            paddingBottom: 9,
            left: 80,
            top: 738,
            position: 'absolute',
            background: '#E6DEFF',
            boxShadow: '0px 15px 35px #1D0160',
            borderRadius: 10,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: '#1D0160',
              fontSize: 30,
              fontFamily: 'Poppins',
              fontWeight: '800',
              letterSpacing: 9,
              wordWrap: 'break-word',
            }}
          >
            LEARN MORE
          </div>
        </div>
        <div
          style={{
            width: 357,
            height: 124.67,
            left: 80,
            top: 80,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 0,
              position: 'absolute',
              color: '#E6DEFF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            Make a
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 30,
              position: 'absolute',
              color: '#E6DEFF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            wish that
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 75,
              position: 'absolute',
              color: '#E6DEFF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            will come true
          </div>
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 417,
            top: 637,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#CBBEFF',
            }}
          ></div>
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 112,
            top: 673,
            position: 'absolute',
            transform: 'rotate(-180deg)',
            transformOrigin: '0 0',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#CBBEFF',
            }}
          ></div>
        </div>
        <div
          style={{
            left: 233,
            top: 656,
            position: 'absolute',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 6,
            display: 'inline-flex',
          }}
        >
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background: '#E6DEFF',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(255, 251, 255, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(255, 251, 255, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
        </div>
        <div
          style={{
            left: 40,
            top: 1,
            position: 'absolute',
            textAlign: 'center',
            color: '#1D0160',
            fontSize: 25,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 6.25,
            wordWrap: 'break-word',
          }}
        >
          WISHLIST
        </div>
      </div>
      <div
        style={{
          width: 529,
          height: 857,
          left: 599,
          top: 2449,
          position: 'absolute',
        }}
      >
        <div
          style={{
            width: 529,
            height: 857,
            left: 0,
            top: 0,
            position: 'absolute',
            background: '#7ADB88',
            borderRadius: 40,
          }}
        />
        <div
          style={{
            left: 40,
            top: 1,
            position: 'absolute',
            textAlign: 'center',
            color: '#002108',
            fontSize: 25,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 6.25,
            wordWrap: 'break-word',
          }}
        >
          BUSINESS
        </div>
        <div
          style={{
            width: 489,
            height: 817,
            left: 20,
            top: 20,
            position: 'absolute',
            background: '#003913',
            borderRadius: 20,
          }}
        />
        <div
          style={{
            width: 369,
            left: 80,
            top: 245,
            position: 'absolute',
            color: '#F6FFF1',
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 1,
            wordWrap: 'break-word',
          }}
        >
          Fill in all the standard ad aspects you know and love Throw in your
          picture, video, or GIF media content Add surveys to help understand
          your customers. Organize, manage, and version control your ads!
        </div>
        <div
          style={{
            width: 357,
            height: 124.67,
            left: 80,
            top: 80,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 0,
              position: 'absolute',
              color: '#F6FFF1',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            Build out
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 30,
              position: 'absolute',
              color: '#F6FFF1',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            digital
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 75,
              position: 'absolute',
              color: '#F6FFF1',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            ads
          </div>
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 417,
            top: 637,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#F6FFF1',
            }}
          ></div>
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 112,
            top: 673,
            position: 'absolute',
            transform: 'rotate(-180deg)',
            transformOrigin: '0 0',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#F6FFF1',
            }}
          ></div>
        </div>
        <div
          style={{
            width: 369,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 9,
            paddingBottom: 9,
            left: 80,
            top: 738,
            position: 'absolute',
            background: '#F6FFF1',
            boxShadow: '0px 15px 35px #002108',
            borderRadius: 10,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: '#002108',
              fontSize: 30,
              fontFamily: 'Poppins',
              fontWeight: '800',
              letterSpacing: 9,
              wordWrap: 'break-word',
            }}
          >
            LEARN MORE
          </div>
        </div>
        <div
          style={{
            left: 233,
            top: 656,
            position: 'absolute',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 6,
            display: 'inline-flex',
          }}
        >
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background: '#F6FFF1',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(246, 255, 241, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(246, 255, 241, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          width: 529,
          height: 857,
          left: 1153,
          top: 2449,
          position: 'absolute',
        }}
      >
        <div
          style={{
            width: 529,
            height: 857,
            left: 0,
            top: 0,
            position: 'absolute',
            background: '#A9C7FF',
            borderRadius: 40,
          }}
        />
        <div
          style={{
            left: 40,
            top: 1,
            position: 'absolute',
            textAlign: 'center',
            color: '#001B3D',
            fontSize: 25,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 6.25,
            wordWrap: 'break-word',
          }}
        >
          PLATFORM
        </div>
        <div
          style={{
            width: 489,
            height: 817,
            left: 20,
            top: 20,
            position: 'absolute',
            background: '#003063',
            borderRadius: 20,
          }}
        />
        <div
          style={{
            width: 357,
            height: 124.67,
            left: 80,
            top: 80,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 0,
              position: 'absolute',
              color: '#D6E3FF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            Grow a
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 30,
              position: 'absolute',
              color: '#D6E3FF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            sustainable
          </div>
          <div
            style={{
              width: 357,
              height: 49.67,
              left: 0,
              top: 75,
              position: 'absolute',
              color: '#D6E3FF',
              fontSize: 40,
              fontFamily: 'Poppins',
              fontWeight: '900',
              letterSpacing: 2,
              wordWrap: 'break-word',
            }}
          >
            business model
          </div>
        </div>
        <div
          style={{
            width: 369,
            left: 80,
            top: 245,
            position: 'absolute',
            color: '#D6E3FF',
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: '600',
            letterSpacing: 1,
            wordWrap: 'break-word',
          }}
        >
          Support your platform with a sustainable business model. Earn revenue
          from ads that your users actually want to see.
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 417,
            top: 637,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#A9C7FF',
            }}
          ></div>
        </div>
        <div
          style={{
            width: 36,
            height: 36,
            left: 112,
            top: 673,
            position: 'absolute',
            transform: 'rotate(-180deg)',
            transformOrigin: '0 0',
          }}
        >
          <div
            style={{
              width: 17.66,
              height: 30,
              left: 9.38,
              top: 3,
              position: 'absolute',
              background: '#A9C7FF',
            }}
          ></div>
        </div>
        <div
          style={{
            width: 369,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 9,
            paddingBottom: 9,
            left: 80,
            top: 738,
            position: 'absolute',
            background: '#D6E3FF',
            boxShadow: '0px 15px 35px #001B3D',
            borderRadius: 10,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'inline-flex',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: '#001B3D',
              fontSize: 30,
              fontFamily: 'Poppins',
              fontWeight: '800',
              letterSpacing: 9,
              wordWrap: 'break-word',
            }}
          >
            LEARN MORE
          </div>
        </div>
        <div
          style={{
            left: 233,
            top: 656,
            position: 'absolute',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: 6,
            display: 'inline-flex',
          }}
        >
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background: '#D6E3FF',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(253, 251, 255, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
          <div style={{ width: 17, height: 17, position: 'relative' }}>
            <div
              style={{
                width: 17,
                height: 17,
                left: 0,
                top: 0,
                position: 'absolute',
                background:
                  'linear-gradient(180deg, rgba(253, 251, 255, 0) 0%, rgba(255, 251, 255, 0.13) 0%, rgba(255, 251, 255, 0.25) 27%, rgba(255, 251, 255, 0.27) 30%, rgba(255, 251, 255, 0.55) 84%)',
                borderRadius: 9999,
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: 25,
          paddingRight: 25,
          paddingTop: 15,
          paddingBottom: 15,
          left: 346,
          top: 85,
          position: 'absolute',
          background: '#77F8DE',
          boxShadow: '0px 25px 30px #00201B',
          borderRadius: 25,
          overflow: 'hidden',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 45,
          display: 'inline-flex',
        }}
      >
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 8,
            paddingBottom: 8,
            background: '#005045',
            borderRadius: 16.25,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'flex',
          }}
        >
          <div
            style={{
              color: '#77F8DE',
              fontSize: 38,
              fontFamily: 'Poppins',
              fontWeight: '900',
              wordWrap: 'break-word',
            }}
          >
            WISHING WELL
          </div>
        </div>
        <div
          style={{
            paddingLeft: 9,
            paddingRight: 9,
            paddingTop: 10,
            paddingBottom: 10,
            background: '#77F8DE',
            borderRadius: 16.75,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'flex',
          }}
        >
          <div
            style={{
              color: '#005045',
              fontSize: 32,
              fontFamily: 'Poppins',
              fontWeight: '900',
              wordWrap: 'break-word',
            }}
          >
            WISHLIST
          </div>
        </div>
        <div
          style={{
            paddingLeft: 9,
            paddingRight: 9,
            paddingTop: 10,
            paddingBottom: 10,
            background: '#77F8DE',
            borderRadius: 16.75,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'flex',
          }}
        >
          <div
            style={{
              color: '#005045',
              fontSize: 32,
              fontFamily: 'Poppins',
              fontWeight: '900',
              wordWrap: 'break-word',
            }}
          >
            BUSINESS
          </div>
        </div>
        <div
          style={{
            paddingLeft: 9,
            paddingRight: 9,
            paddingTop: 10,
            paddingBottom: 10,
            background: '#77F8DE',
            borderRadius: 16.75,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            display: 'flex',
          }}
        >
          <div
            style={{
              color: '#005045',
              fontSize: 32,
              fontFamily: 'Poppins',
              fontWeight: '900',
              wordWrap: 'break-word',
            }}
          >
            PLATFORM
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header

import img1 from './assets/wish1.jpg'
import img2 from './assets/wish2.jpg'
import img3 from './assets/wish3.jpg'
import './App.css'
import { useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'

import { IconButton, useMediaQuery, useTheme } from '@mui/material'

function Wishlist() {
  const [currentPage, setCurrentPage] = useState(0)
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('sm'))

  const renderPageOne = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: large ? '64px' : '16px',
          flexDirection: large ? 'row' : 'column',
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <h1 style={{ padding: 16, margin: 0 }}>Make A Wish</h1>
          <p style={{ padding: 16, margin: 0, color: '#282c34' }}>
            Curate a Wishlist of products and services you ACTUALLY want to see.
            Don't let algorithms tell you what to buy. Add keywords, brands,
            images, and more to your wishlist to find products you want to find.
          </p>
        </div>
        <div>
          <img src={img1} className="App-logo" alt="wishlist-1" />
        </div>
      </div>
    )
  }
  const renderPageTwo = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: large ? '64px' : '16px',
          flexDirection: large ? 'row' : 'column',
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <h1 style={{ padding: 16, margin: 0 }}>
            Discover Products That You'll Love
          </h1>
          <p style={{ padding: 16, margin: 0, color: '#282c34' }}>
            Get exclusive offers, discounts, and deals
          </p>
        </div>
        <div>
          <img src={img2} className="App-logo" alt="wishlist-2" />
        </div>
      </div>
    )
  }
  const renderPageThree = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: large ? '64px' : '16px',
          flexDirection: large ? 'row' : 'column',
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <h1 style={{ padding: 16, margin: 0 }}>
            Take Ownership of Your Data
          </h1>
          <p style={{ padding: 16, margin: 0, color: '#282c34' }}>
            Own your data instead of letting other platforms sell it. Configure
            your profile vault, opt in to share, and get paid for the same
            metrics that are currently being sold across web2.
          </p>
        </div>
        <div>
          <img src={img3} className="App-logo" alt="wishlist-3" />
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {false && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            width: '100%',
          }}
        >
          {large && (
            <p style={{ color: ' white', margin: '32px' }}>Wishlists</p>
          )}

          <div
            style={{
              backgroundColor: '#4168B2',
              borderRadius: '16px',
              margin: '32px',
              padding: '16px',
              cursor: 'pointer',
            }}
            //onClick={handleOpenSignUpForm}
          >
            <a
              href="https://chrome.google.com/webstore/detail/wishingwell/okcpajkhpiocgalbajklffnhfoaenmhn"
              target="_blank"
              rel="noreferrer"
            >
              Sign Up For Beta
            </a>
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {large && (
          <div
            style={{
              marginLeft: '32px',
              height: '90vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {' '}
            {currentPage > 0 ? (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#1D0160',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <ArrowBackIosIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#1D0160',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(2)}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}
          </div>
        )}

        <div style={{ margin: '32px', marginTop: large ? '20vh' : '15vh' }}>
          {currentPage === 0 ? renderPageOne() : null}
          {currentPage === 1 ? renderPageTwo() : null}
          {currentPage === 2 ? renderPageThree() : null}
          {!false && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',

                width: '100%',
              }}
            >
              <div
                style={{
                  backgroundColor: '#332074',
                  borderRadius: '16px',
                  margin: '32px',
                  padding: '16px',
                  cursor: 'pointer',
                }}
                //onClick={handleOpenSignUpForm}
              >
                <a
                  href="https://chrome.google.com/webstore/detail/wishingwell/okcpajkhpiocgalbajklffnhfoaenmhn"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#FFFBFF' }}
                >
                  Sign Up For Beta
                </a>
              </div>
            </div>
          )}
        </div>

        {large && (
          <div
            style={{
              marginRight: '32px',
              height: '90vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {' '}
            {currentPage < 2 ? (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#1D0160',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setCurrentPage(0)}
                sx={{
                  color: 'white',
                  backgroundColor: '#1D0160',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {currentPage === 0 ? (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(0)}>
            ◉
          </div>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(0)}>
            ◎
          </div>
        )}
        {currentPage === 1 ? (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(1)}>
            ◉
          </div>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(1)}>
            ◎
          </div>
        )}{' '}
        {currentPage === 2 ? (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(2)}>
            ◉
          </div>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(2)}>
            ◎
          </div>
        )}
      </div>
    </div>
  )
}

export default Wishlist

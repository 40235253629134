import { createTheme, experimental_sx as sx } from '@mui/material/styles'
import { green, purple } from '@mui/material/colors'

/*
.palette
.typography
.spacing
.breakpoints
.zIndex
.transitions
.components
*/
const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'Jockey One', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      100: '#00201B',
      200: '#00382F',
      300: '#005045',
      400: '#006B5C',
      500: '#58DBC3',
      600: '#77F8DE',
      700: '#F3FFFA',
    },
    wishlist: {
      100: '#1D0160',
      200: '#332074',
      300: '#4A398C',
      400: '#6251A6',
      500: '#CBBEFF',
      600: '#E6DEFF',
      700: '#FFFBFF',
    },
    business: {
      100: '#002108',
      200: '#003913',
      300: '#00531F',
      400: '#006E2C',
      500: '#7ADB88',
      600: '#95F8A2',
      700: '#F6FFF1',
    },
    platform: {
      100: '#001B3D',
      200: '#003063',
      300: '#00468B',
      400: '#0085FF',
      500: '#A9C7FF',
      600: '#D6E3FF',
      700: '#FDFBFF',
    },
    secondary: {
      main: '#4168B2',
    },
    gunMetal: {
      main: '#2B303E',
      light: 'rgba(205, 210, 213, 1)',
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
        disableElevation: true,
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '40px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: 'white',
          color: 'gunMetal.main',
        },
      },
    },
    MuiGrid: {
      // todo add in variants for spacing etc container vs item
      defaultProps: {
        // The props to change the default for.
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
  },
})

export default theme

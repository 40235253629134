import { useMediaQuery, useTheme } from '@mui/material'
import theme from '../theme'
import { useState } from 'react'

function CTASignUpButton(props) {
  const [hovered, setHovered] = useState(false)
  const theme = useTheme()
  if (hovered) {
    return (
      <div
        onClick={props.onClick}
        onMouseLeave={() => setHovered(false)}
        style={{
          cursor: 'pointer',
          height: '91px',
          paddingLeft: 46,
          paddingRight: 46,
          paddingTop: 36,
          paddingBottom: 36,
          background: '#77F8DE',
          boxShadow: '0px 0px 25px rgba(119, 248, 222, 0.50)',
          borderRadius: 10,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          display: 'inline-flex',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            color: '#00201B',
            fontSize: `calc(10px + 2vmin)`,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 9,
            wordWrap: 'break-word',
          }}
        >
          {props.text}
        </div>
      </div>
    )
  } else {
    return (
      <div
        onMouseEnter={() => setHovered(true)}
        style={{
          height: 91,
          paddingLeft: 45,
          paddingRight: 45,
          paddingTop: 35,
          paddingBottom: 35,
          background: '#77F8DE',
          boxShadow: '0px 20px 55px #00201B',
          borderRadius: 10,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          display: 'flex',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            color: '#00201B',
            fontSize: `calc(10px + 2vmin)`,
            fontFamily: 'Poppins',
            fontWeight: '800',
            letterSpacing: 9,
            wordWrap: 'break-word',
          }}
        >
          {props.text}
        </div>
      </div>
    )
  }
}

export default CTASignUpButton

import img1 from './assets/bus1.png'
import img2 from './assets/bus2.png'
import img3 from './assets/bus3.png'
import './App.css'
import { useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'

import { IconButton, useMediaQuery, useTheme } from '@mui/material'

function Business() {
  const [currentPage, setCurrentPage] = useState(0)
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('sm'))

  const renderPageOne = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: large ? '64px' : '16px',
          flexDirection: large ? 'row' : 'column',
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <h1 style={{ padding: 16, margin: 0 }}>Build out digital ads</h1>
          <p style={{ padding: 16, margin: 0 }}>
            Fill in all the standard ad aspects you know and love Throw in your
            picture, video, or GIF media content Add surveys to help understand
            your customers. Organize, manage, and version control your ads!
          </p>
        </div>
        <div>
          <img src={img1} className="App-logo" alt="business-1" />
        </div>
      </div>
    )
  }
  const renderPageTwo = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: large ? '64px' : '16px',

          flexDirection: large ? 'row' : 'column',
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <h1 style={{ padding: 16, margin: 0 }}>Reach Your Ideal Customers</h1>
          <p style={{ padding: 16, margin: 0 }}>
            Find your audience with multi-media ad campaigns across platforms.
            Amplify your product discovery with our search engine. Reach
            customers directly through wishlists, through content-creators, and
            platforms across web3.
          </p>
        </div>
        <div>
          <img src={img2} className="App-logo" alt="business-2" />
        </div>
      </div>
    )
  }
  const renderPageThree = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: large ? '64px' : '16px',
          flexDirection: large ? 'row' : 'column',
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <h1 style={{ padding: 16, margin: 0 }}>Measure Your Success</h1>
          <p style={{ padding: 16, margin: 0 }}>
            Monitor your campaigns with on-chain analytics. See how your
            campaigns are performing with real-time metrics. Understand your
            audience with survey data. Iterate on your strategies with our
            flexible, customizable, and open platform.
          </p>
        </div>
        <div>
          <img src={img3} className="App-logo" alt="business-3" />
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {false && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            width: '100%',
          }}
        >
          {large && <p style={{ color: ' white', margin: '32px' }}>Business</p>}

          <div
            style={{
              backgroundColor: '#68C17D',
              borderRadius: '16px',
              margin: large ? '32px' : '8px',
              padding: '16px',
            }}
          >
            <a
              href="https://apps.shopify.com/wishingwell"
              target="_blank"
              rel="noreferrer"
            >
              Create A Business Profile
            </a>
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {large && (
          <div
            style={{
              marginLeft: '32px',
              height: '90vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {' '}
            {currentPage > 0 ? (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#003913',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <ArrowBackIosIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#003913',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(2)}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}
          </div>
        )}
        <div style={{ margin: '32px', marginTop: large ? '20vh' : '15vh' }}>
          {currentPage === 0 ? renderPageOne() : null}
          {currentPage === 1 ? renderPageTwo() : null}
          {currentPage === 2 ? renderPageThree() : null}
          {!false && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',

                width: '100%',
              }}
            >
              <div
                style={{
                  backgroundColor: '#003913',
                  borderRadius: '16px',
                  margin: large ? '32px' : '8px',
                  padding: '16px',
                }}
              >
                <a
                  href="https://apps.shopify.com/wishingwell"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#F6FFF1',
                  }}
                >
                  Create A Business Profile
                </a>
              </div>
            </div>
          )}
        </div>
        {large && (
          <div
            style={{
              marginRight: '32px',
              height: '90vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {' '}
            {currentPage < 2 ? (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#003913',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#003913',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
                onClick={() => setCurrentPage(0)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {currentPage === 0 ? (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(0)}>
            ◉
          </div>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(0)}>
            ◎
          </div>
        )}
        {currentPage === 1 ? (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(1)}>
            ◉
          </div>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(1)}>
            ◎
          </div>
        )}{' '}
        {currentPage === 2 ? (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(2)}>
            ◉
          </div>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(2)}>
            ◎
          </div>
        )}
      </div>
    </div>
  )
}

export default Business
